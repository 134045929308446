<template>
  <div
    id="container"
    class="container d-flex flex-column justify-content-between"
  >
    <div v-if="ready">
      <div class="py-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          :src="logo"
          alt=""
          width="20%"
          height="20%"
        />
        <h2>{{ pageContent[0].title }}</h2>
        <p class="lead">{{ pageContent[0].subtitle }}.</p>
        <p class="lead">{{ pageContent[0].description }}.</p>
      </div>
      <!-- <div class="py-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          src="/logo.png"
          alt=""
          width="20%"
          height="20%"
        />
        <h2>Pagamento Matrícula / reserva de vaga 2022</h2>
        <p class="lead">
          Para garantia da vaga é necessário o preenchimento do requerimento e
          pagamento da antecipação 1ª parcela da anuidade.
        </p>
      </div> -->
      <hr />
      <h4 class="mb-3">{{ data.reserve.client_name }}</h4>
      <hr />

      <b-row>
        <b-col md="8">
          <PanelItems v-model="model" :errors="errors" />
          {{ data.pageContent[0].warning }}
        </b-col>
        <b-col md="4">
          <FormSelect
            :label="'Melhor dia de Pagamento'"
            :selectOptions="paymentDatesOptions"
            v-model="model.reserve.unity_payment_day"
            :ready="true"
            class="mb-3"
          />
          <PanelValues v-model="model" />
          <button
            v-if="!submiting"
            @click="save_reservation()"
            class="btn btn-primary btn-lg btn-block"
          >
            Confirmar reserva
          </button>
          <button
            v-else
            @click="save_reservation()"
            class="btn btn-primary btn-lg btn-block"
            disabled
          >
            Confirmar reserva<b-spinner class="ml-2" small></b-spinner>
          </button>
        </b-col>
      </b-row>
      <div class="copyright">
        <div v-html="data.pageContent[0].content_footer"></div>
      </div>
    </div>
    <footer class="my-5 pt-5 text-muted text-center text-small">
      <p class="mb-1">
        &copy; {{ currentYear }} - {{ companyName }} - Todos os direitos
        reservados.
      </p>
    </footer>   
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      ready: false,
      submiting: false,
      errors: [],
      model: {
        id: "",
        application_id: "",
        unity_id: "",
        reserve: {
          current_year: "2024",
          plan_id: "98b7cfbf-eab5-4c18-bf62-70e449bec2e5",
          group_id: "67219535-2374-483f-99b5-9ed09bb55b11",
          period_id: "a5b2c884-770b-4cca-89fa-c6c7216d66d5",
          unity_payment_day: "",
        },
        enrollment_value: {
          id: "",
          group_installment_id: "",
        },
        items: [],
        total: 0,
      },
    };
  },
  components: {
    PanelItems: () => import("./components/PanelItems.vue"),
    PanelValues: () => import("./components/PanelValues.vue"),
  },
  computed: {
    paymentDatesOptions() {
      return _.map(this.$store.state.confirmReservation.paymentDates, (d) => {
        return { value: d.payment_day, text: d.payment_day };
      });
    },
    data() {
      return this.$store.state.confirmReservation.data;
    },
    plan() {
      return this.data.plan;
    },
    products() {
      return this.data.product;
    },
    enrollment() {
      return this.data.enrollmentValue;
    },
    pageContent() {
      return this.data.pageContent;
    },
    logo() {
      return `${process.env.VUE_APP_PREFIX_LOGO}/logo/${this.data.pageContent[0].unity_id}/logo.png`;
    },
    currentYear() {
      return moment().year();
    },
    companyName() {
      let companyName = "";
      try {
        companyName = this.data.unity.company_name;
      } catch (error) {}
      return companyName;
    },
    // total() {
    //   const enrollmentValue = [this.enrollment[0].total * 1];
    //   const planValue = [this.plan.total * 1];
    //   const productsValue = _.map(this.model.items, (item) => {
    //     return item.total * 1;
    //   });
    //   const total = _.reduce(
    //     [...enrollmentValue, ...planValue, ...productsValue],
    //     (a, b) => {
    //       return a + b;
    //     },
    //     0
    //   );
    //   return total;
    // },
  },
  methods: {
    async save_reservation() {
      this.errors = [];
      if (this.data.enrollmentValue.length > 0) {
        if (!this.model.enrollment_value.id) {
          this.errors.push("enrollment_value");
        }
      }
      if (this.model.items.length > 0) {
        _.forEach(this.model.items, (item) => {
          if (!item.group_installment_id) {
            if (this.has_installments(item.product_id)) {
              this.errors.push(item.product_id);
            }
          }
        });
      }
      if (this.errors.length == 0) {
        this.submiting = true;
        console.log("model before sent", this.model);
        const response = await this.$store.dispatch(
          "confirmReservation/save_reservation",
          this.model
        );
        this.submiting = false;
        console.log(response);
        if (response.link) {
          window.location.href = response.link;
        }
      }
    },
    has_installments(id) {
      const items = _.filter(this.products, (product) => {
        return product.id == id && product.productinstallment.length > 0;
      });
      if (items.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    async get_payment_dates() {
      await this.$store.dispatch("http_get", {
        url: `/unity-payment-day/filter/${this.model.unity_id}`,
        vuex: "confirmReservation/paymentDates",
      });
    },
  },
  async mounted() {
    const enrollmentFormId = this.$route.params.id;

    await this.$store.dispatch("confirmReservation/initApp", enrollmentFormId);

    const file = JSON.parse(this.data.reserve.file);

    console.log(file);

    this.model.id = enrollmentFormId;

    this.model.unity_id = file.unity_id;

    this.model.reserve = file.reserve;

    this.model.application_id = file.application_id;

    // document.getElementById("container").style.height = `500px`
    document.getElementById(
      "container"
    ).style.minHeight = `${window.innerHeight}px`;

    // this.model.enrollment_form_id = enrollmentFormId;

    // this.model.unity_id = this.data.plan.unity_id;
    // this.model.client_id = this.data.enrollmentForm.client_id;
    // this.model.financial_client_id =
    //   this.data.enrollmentForm.financial_client_id;
    // this.model.legal_client_id = this.data.enrollmentForm.legal_client_id;
    await this.get_payment_dates();
    this.ready = true;
  },
};
</script>

<style lang="scss">
.div-header {
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.03);
}
.div-body {
  padding: 0.5rem;
  padding-left: 1.5rem;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.container {
  min-height: 100vh;
}
</style>
